import * as React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

import cooking from '../../images/cooking.jpg';
import fireplace from '../../images/fireplace.jpg';
import subassemplies from '../../images/subassemblies.jpg';

const Home: React.FC = () => {
  return (
    <React.Fragment>
      <section id="wm-body">
        <div className="wm-fullwidth-container">
          <div id="wm-home-hero-image">
            <div className="container d-flex align-items-start justify-content-center">
              {/* <div id="wm-home-tagline">Custom Tube &amp; Pipe Fabrication since 1956!</div> */}
            </div>
          </div>
        </div>
      </section>
      <section style={{ marginTop: 0, marginBottom: 20 }}>
        <Container>
          <Row>
            <Col xs={12}>
              <h4 className="project-header text-center">
                Custom Tube &amp; Pipe Fabrication since 1956!
              </h4>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4}>
              <Card>
                <Card.Img variant="top" src={cooking} />
                <Card.Body>
                  <Card.Title>Commercial and Residential Cooking</Card.Title>
                  <Card.Text>
                    The capabilities that Woodmack Products, Inc. has developed over the past 50+
                    years has left us well suited to service the commercial and residential food
                    service industry, manufacturing manifolds and burners for gas appliances.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <Card.Img variant="top" src={fireplace} />
                <Card.Body>
                  <Card.Title>Heating Tube &amp; Pipe Solutions</Card.Title>
                  <Card.Text>
                    At Woodmack, we specialize in tube and pipe fabrication for various heating
                    applications. Our manufacturing expertise and material knowledge allow us to
                    provide quality manufactured products composed of materials suited for specific
                    heating applications. From commercial boiler tubing and manifolds to residential
                    fireplace components, we provide products that are precision fabricated from
                    quality materials, to meet your specific application needs.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <Card.Img variant="top" src={subassemplies} />
                <Card.Body>
                  <Card.Title>Tubing and Subassemblies</Card.Title>
                  <Card.Text>
                    As a full service manufacturer of tube and pipe products, Woodmack possesses the
                    manufacturing and assembly capabilities to deliver complex assemblies that
                    require multiple manufacturing processes. Our facility is equipped with advanced
                    metal forming equipment, operated by experienced technicians. The advantage of
                    50 years of industry experience equate to flexible manufacturing processes with
                    the ability to meet precision specifications and tight schedules.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Home;
