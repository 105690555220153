import React from 'react';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import onNavigate from '../modules/onNavigate';
import { SiteViews } from '../models/enums/siteViews';

const PortfolioList: React.FC = () => {
  return (
    <ListGroup>
      <ListGroupItem>
        <Button
          variant="link"
          onClick={() => onNavigate(`#${SiteViews.MetalFormingStainlessSteelTube}`)}
        >
          Fabrication of a Stainless Steel Ring Burner for the Industrial Cooking Industry
        </Button>
      </ListGroupItem>
      <ListGroupItem>
        <Button
          variant="link"
          onClick={() => onNavigate(`#${SiteViews.CustomFabricationStainlessSteelBurner}`)}
        >
          Fabricated Stainless Steel W Burner for the Outdoor Grilling Industry
        </Button>
      </ListGroupItem>
      <ListGroupItem>
        <Button
          variant="link"
          onClick={() =>
            onNavigate(`#${SiteViews.PrecisionFabricationYShapedStainlessSteelBurner}`)
          }
        >
          Fabricated Stainless Steel Y Shaped Burner for the Residential Fireplace Industry
        </Button>
      </ListGroupItem>
      <ListGroupItem>
        <Button
          variant="link"
          onClick={() => onNavigate(`#${SiteViews.CustomFabricatedDoubleWallManifold}`)}
        >
          Fabrication of a Double Wall Manifold for the Commercial Boiler Industry
        </Button>
      </ListGroupItem>
      <ListGroupItem>
        <Button
          variant="link"
          onClick={() => onNavigate(`#${SiteViews.MetalFabricationCommercialBurner}`)}
        >
          Fabrication of a U Shaped Commercial Burner for the Commercial Cooking Industry
        </Button>
      </ListGroupItem>
      <ListGroupItem>
        <Button
          variant="link"
          onClick={() => onNavigate(`#${SiteViews.CustomFabricatedAluminumFireplaceConnectorTube}`)}
        >
          Fabrication and Assembly of an Aluminum Fireplace Connector Tube for the Residential
          Fireplace
        </Button>
      </ListGroupItem>
    </ListGroup>
  );
};

export default PortfolioList;
