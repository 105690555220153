import { navigate } from 'hookrouter';
import * as React from 'react';
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

import logo from '../images/logo.png';
import { SiteViews } from '../models/enums/siteViews';

const Header: React.FC = () => {
  const onNavigateHome = (event: React.SyntheticEvent) => {
    event.preventDefault();
    navigate('/');
  };

  const onNavigateQuote = (event: React.SyntheticEvent) => {
    event.preventDefault();
    navigate(SiteViews.Quote);
  };

  const onNavigate = (eventKey: any, event: React.SyntheticEvent<any>) => {
    switch (eventKey) {
      case '#home':
        navigate('/');
        break;
      default:
        const target = eventKey.replace('#', '/');
        navigate(target);
        break;
    }
    event.preventDefault();
  };

  return (
    <header id="wm-header">
      <Navbar variant="light" bg="#FFFFFF" expand="lg" style={{ padding: 0, margin: 0 }}>
        <Container>
          <div className="d-flex flex-grow-1">
            <Navbar.Brand>
              <Button variant="link" onClick={onNavigateHome}>
                <div style={{ display: 'inline-block', float: 'left' }}>
                  <img
                    src={logo}
                    width="138"
                    height="131"
                    className="d-lg-inline-block align-top"
                    alt="Houses Wtih Holly"
                  />
                </div>
                <div style={{ height: 131 }}>
                  <span
                    style={{
                      position: 'absolute',
                      top: 64,
                      paddingBottom: 10,
                      paddingLeft: 8,
                      textAlign: 'left',
                      fontSize: '1.8em',
                      color: '#E40402',
                      fontWeight: 'bold',
                    }}
                  >
                    Woodmack
                    <br />
                    Products, Inc.
                  </span>
                </div>
              </Button>
            </Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="text-right flex-column flex-grow-1 align-items-end"
          >
            <div
              className="align-items-center d-none d-lg-flex mr-2 mt-4"
              style={{ color: '#E40402' }}
            >
              <a href="mailto:frontdesk@mastermfggroup.com" style={{ color: '#E40402' }}>
                frontdesk@mastermfggroup.com
              </a>
              &nbsp;|&nbsp;
              {/* eslint-disable-next-line */}
              <a href="tel:+14236672582" style={{ color: '#E40402' }}>
                423.428.7810
              </a>
              &nbsp;|&nbsp;
              {/* eslint-disable-next-line */}
              <a
                onClick={onNavigateQuote}
                href="#"
                style={{ color: '#E40402', fontWeight: 'bold' }}
              >
                Request a Quote
              </a>
            </div>
            <Nav className="ml-auto flex-nowrap">
              <Nav.Link onSelect={onNavigate} href={`#${SiteViews.About}`}>
                About
              </Nav.Link>
              <Nav.Link onSelect={onNavigate} href={`#${SiteViews.Fabrication}`}>
                Fabrication
              </Nav.Link>
              <Nav.Link onSelect={onNavigate} href={`#${SiteViews.Engineering}`}>
                Engineering
              </Nav.Link>
              <Nav.Link onSelect={onNavigate} href={`#${SiteViews.Applications}`}>
                Applications
              </Nav.Link>
              <NavDropdown title="Portfolio" id="collasible-nav-dropdown">
                <NavDropdown.Item onSelect={onNavigate} href={`#${SiteViews.Portfolio}`}>
                  Project List
                </NavDropdown.Item>
                <NavDropdown.Item
                  onSelect={onNavigate}
                  href={`#${SiteViews.MetalFormingStainlessSteelTube}`}
                >
                  Ring Burner
                  <br />
                  Industrial Cooking
                </NavDropdown.Item>
                <NavDropdown.Item
                  onSelect={onNavigate}
                  href={`#${SiteViews.CustomFabricationStainlessSteelBurner}`}
                >
                  Steel Burner
                  <br />
                  Outdoor Grilling
                </NavDropdown.Item>
                <NavDropdown.Item
                  onSelect={onNavigate}
                  href={`#${SiteViews.PrecisionFabricationYShapedStainlessSteelBurner}`}
                >
                  Y Shaped Burner
                  <br />
                  Residential Fireplace
                </NavDropdown.Item>
                <NavDropdown.Item
                  onSelect={onNavigate}
                  href={`#${SiteViews.CustomFabricatedDoubleWallManifold}`}
                >
                  Double Walled Manifold
                  <br />
                  Commercial Boiler
                </NavDropdown.Item>
                <NavDropdown.Item
                  onSelect={onNavigate}
                  href={`#${SiteViews.MetalFabricationCommercialBurner}`}
                >
                  U Shaped Burner
                  <br />
                  Commercial Cooking
                </NavDropdown.Item>
                <NavDropdown.Item
                  onSelect={onNavigate}
                  href={`#${SiteViews.CustomFabricatedAluminumFireplaceConnectorTube}`}
                >
                  Aluminum Connecture Tube
                  <br />
                  Residential Fireplace
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link onSelect={onNavigate} href={`#${SiteViews.Contact}`}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
