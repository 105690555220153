export enum SiteViews {
  Home = 'home',
  About = 'about',
  Fabrication = 'fabrication',
  Engineering = 'engineering',
  Applications = 'applications',
  Portfolio = 'portfolio',
  Contact = 'contact',
  Quote = 'quote',
  MetalFormingStainlessSteelTube = 'metal-forming-stainless-steel-tube',
  CustomFabricationStainlessSteelBurner = 'custom-fabrication-stainless-steel-burner',
  PrecisionFabricationYShapedStainlessSteelBurner = 'precision-fabrication-y-shaped-stainless-steel-burner',
  CustomFabricatedDoubleWallManifold = 'custom-fabricated-double-wall-manifold',
  MetalFabricationCommercialBurner = 'metal-fabrication-commercial-burner',
  CustomFabricatedAluminumFireplaceConnectorTube = 'custom-fabricated-aluminum-fireplace-connector-tube',
  PrivacyPolicy = 'privacy-policy',
}
