import React from 'react';
import { Button, Col, Container, Row, ListGroup, ListGroupItem } from 'react-bootstrap';
import onNavigate from '../../modules/onNavigate';
import { SiteViews } from '../../models/enums/siteViews';

import cookingTube from '../../images/cooking-img1.jpg';
import heatingTube from '../../images/fireplace-img2.jpg';
import subassemblies from '../../images/subassemblies-img3.jpg';

const Applications: React.FC = () => {
  return (
    <Container className="page-container">
      <Row>
        <Col xs={12}>
          <h3>Tube &amp; Pipe Fabrication Solutions</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h5 className="project-header">
            Commercial &amp; Residential Cooking Tube &amp; Pipe Solutions
          </h5>
        </Col>
      </Row>
      <Row>
        <Col sm={5} md={4} className="d-flex justify-content-center align-items-center">
          <img
            src={cookingTube}
            alt="Commercial and Residential Cooking Tube and Pipe Solutions"
            className="mb-2"
          />
        </Col>
        <Col sm={7} md={8}>
          <p>
            The capabilities that Woodmack Products, Inc. has developed over the past 50+ years has
            left us well suited to service the commercial and residential food service industry,
            manufacturing manifolds and burners for gas appliances.
          </p>
          <p>Here are a few examples of our commercial &amp; residential cooking capabilities:</p>
          <ListGroup>
            <ListGroupItem>
              <Button
                className="text-left"
                variant="link"
                onClick={() => onNavigate(`#${SiteViews.MetalFormingStainlessSteelTube}`)}
              >
                Fabrication of a Stainless Steel Ring Burner for the Industrial Cooking Industry
              </Button>
            </ListGroupItem>
            <ListGroupItem>
              <Button
                className="text-left"
                variant="link"
                onClick={() => onNavigate(`#${SiteViews.CustomFabricationStainlessSteelBurner}`)}
              >
                Fabricated Stainless Steel W Burner for the Outdoor Grilling Industry
              </Button>
            </ListGroupItem>
            <ListGroupItem>
              <Button
                className="text-left"
                variant="link"
                onClick={() => onNavigate(`#${SiteViews.MetalFabricationCommercialBurner}`)}
              >
                Fabrication of a U Shaped Commercial Burner for the Commercial Cooking Industry
              </Button>
            </ListGroupItem>
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h5 className="project-header">Heating Tube &amp; Pipe Solutions</h5>
        </Col>
      </Row>
      <Row>
        <Col sm={5} md={4} className="d-flex justify-content-center align-items-center">
          <img src={heatingTube} alt="Heating Tube and Pipe Solutions" className="mb-2" />
        </Col>
        <Col sm={7} md={8}>
          <p>
            At Woodmack, we specialize in tube and pipe fabrication for various heating
            applications. Our manufacturing expertise and material knowledge allow us to provide
            quality manufactured products composed of materials suited for specific heating
            applications. From commercial boiler tubing and manifolds to residential fireplace
            components, we provide products that are precision fabricated from quality materials, to
            meet your specific application needs.
          </p>
          <p>Here are a few examples of our heating tube &amp; pipe capabilities:</p>
          <ListGroup>
            <ListGroupItem>
              <Button
                className="text-left"
                variant="link"
                onClick={() =>
                  onNavigate(`#${SiteViews.PrecisionFabricationYShapedStainlessSteelBurner}`)
                }
              >
                Fabricated Stainless Steel Y Shaped Burner for the Residential Fireplace Industry
              </Button>
            </ListGroupItem>
            <ListGroupItem>
              <Button
                className="text-left"
                variant="link"
                onClick={() => onNavigate(`#${SiteViews.CustomFabricatedDoubleWallManifold}`)}
              >
                Fabrication of a Double Wall Manifold for the Commercial Boiler Industry
              </Button>
            </ListGroupItem>
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h5 className="project-header">Tubing &amp; Subassemblies</h5>
        </Col>
      </Row>
      <Row>
        <Col sm={5} md={4} className="d-flex justify-content-center align-items-center">
          <img src={subassemblies} alt="Tubing and Subassemblies" className="mb-2" />
        </Col>
        <Col sm={7} md={8}>
          <p>
            As a full service manufacturer of tube and pipe products, Woodmack possesses the
            manufacturing and assembly capabilities to deliver complex assemblies that require
            multiple manufacturing processes. Our facility is equipped with advanced metal forming
            equipment, operated by experienced technicians. The advantage of 50 years of industry
            experience equate to flexible manufacturing processes with the ability to meet precision
            specifications and tight schedules.
          </p>
          <p>Here is an example of our Tubing &amp; Subassembly capabilities:</p>
          <ListGroup>
            <ListGroupItem>
              <Button
                className="text-left"
                variant="link"
                onClick={() =>
                  onNavigate(`#${SiteViews.CustomFabricatedAluminumFireplaceConnectorTube}`)
                }
              >
                Fabrication and Assembly of an Aluminum Fireplace Connector Tube for the Residential
                Fireplace
              </Button>
            </ListGroupItem>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default Applications;
