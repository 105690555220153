import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

import building from '../../images/wpi-building.jpg';

const About : React.FC = () => {

    return (
        <Container className="page-container">
            <Row>
                <Col xs={12}>
                        <h2>About Woodmack Products, Inc.</h2>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img className="img-fluid" src={building} alt="Woodmack Products, Inc." />
                        </div>
                        <p>
                            Woodmack Products Inc. is located in Sacramento, California. We are a privately held manufacturing company with over 
                            50 years of experience, which has enabled us to provide generations of uninterrupted personal service to our clients. 
                            We have over 50,000 sq. ft. production facilities on our four acres to assist you with all your production needs. This 
                            will allow our business to grow and still provide the same level of customer satisfaction we have become known for. 
                            Woodmack has developed a reputation of being a responsive, quality-oriented, just-in-time OEM supplier. By working closely 
                            together with our customers, we are able to establish a partnership that allows us to become a vertical extension of their company.</p>
                        <p>Let Woodmack work for you!</p>
                </Col>
            </Row>
        </Container>
        
    );

}

export default About;
