import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { SiteViews } from '../../models/enums/siteViews';
import onNavigate from '../../modules/onNavigate';

import accentImage from '../../images/commercial_burner_img.jpg';

const UShapedBurner: React.FC = () => {
  return (
    <Container className="page-container">
      <Row>
        <Col xs={12}>
          <h3>Fabrication of a U-Shaped Commercial Burner for the Commercial Cooking Industry</h3>
          <p>
            <img
              src={accentImage}
              alt="U-Shaped Commercial Burner"
              style={{ marginRight: 12 }}
              className="float-left img-fluid"
            />
            This U shaped burner was designed for use in a commercial cooking application; it
            provides an open flame for a large stove used in industrial cooking. At Woodmack
            Products, Inc. we produce over 10,000 of these units annually. Although this represents
            a common burner design, it also features three different port types.
          </p>
          <p>
            Composed of 1.25" diameter, .035" thick, aluminized steel, the finished burner measured
            7" in width and 29" in length. The raw tube was cut to size on our tube shear before
            bending, porting, and forming. The three port types were precision stamped and met
            tolerances of ±0.003". The burner components are then MIG welded to complete the
            assembly.
          </p>
          <p>
            For more information on this project or the processes used to manufacture it, see the
            table below or&nbsp;
            <span className="fake-link" onClick={() => onNavigate(`#${SiteViews.Contact}`)}>
              contact us
            </span>
            &nbsp;directly.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="primary" onClick={() => onNavigate(`#${SiteViews.Portfolio}`)}>
            View Other Portfolio
          </Button>
        </Col>
        <Col>
          <Button
            variant="primary"
            className="float-right"
            onClick={() => onNavigate(`#${SiteViews.Quote}`)}
          >
            Request Quote
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="project-header">Project Highlights:</h4>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p>
            <strong>Description</strong>
            <br />
            This commercial burner is used to provide an open flame to a large stove for industrial
            size cooking.
          </p>
          <div style={{ marginBottom: '1rem' }}>
            <div>
              <strong>Precision Metal Forming Capabilities Applied/Processes</strong>
            </div>
            <div>
              Primary:
              <br />
              Fabrication <br />
              Shearing <br />
              Porting <br />
              Bending <br />
              Modification <br />
              Stamping <br />
              Welding
            </div>
          </div>
          <p>
            <strong>Equipment Used to Manufacture Part</strong>
            <br />
            Tube Shear, Proprietary CNC Porting Machines, CNC Bender, Punch Press, MIG Welder
          </p>
          <p>
            <strong>Expertise</strong>
            <br />
            Metal Fabrication
          </p>
          <p>
            <strong>Overall Part Dimensions</strong>
            <br />
            Length: 29" Width: 7"
          </p>
        </Col>
        <Col md={6}>
          <p>
            <strong>Tightest Tolerances</strong>
            <br />± 0.003"
          </p>
          <p>
            <strong>Material Used</strong>
            <br />Ø 1.25" Aluminized Steel Thickness: 0.035"
          </p>
          <p>
            <strong>Industry for Use</strong>
            <br />
            Commercial Cooking Industry
          </p>
          <p>
            <strong>Volume</strong>
            <br />
            10,000+ annually
          </p>
          <p>
            <strong>Standards Met</strong>
            <br />
            Customer Specifications, 2D CAD Drawing
          </p>
          <p>
            <strong>Product Name</strong>
            <br />U Shaped Commercial Burner
          </p>
          <p>
            <strong>Why did you choose this project to showcase?</strong>
            <br />
            It shows 3 different types of ports incorporated into a single design.
            <br />
            It represents a very common burner design.
            <br />
            Very high volume.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default UShapedBurner;
