import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { SiteViews } from '../../models/enums/siteViews';
import onNavigate from '../../modules/onNavigate';

import accentImage from '../../images/image001.jpg';

const RingBurner: React.FC = () => {
  return (
    <Container className="page-container">
      <Row>
        <Col xs={12}>
          <h3>Fabricated Stainless Steel Burner for the Outdoor Grill Industry</h3>
          <p>
            <img
              src={accentImage}
              alt="Steel Burner"
              style={{ marginRight: 12 }}
              className="float-left img-fluid"
            />
            At Woodmack Products, Inc. we specialize in the fabrication of gas burners for the
            appliance industry. Our reputation as a quality manufacturer is what brought this
            outdoor grill industry customer to us for the fabrication of this W burner. Designed
            with complex features such as an entrance leg and two tube sizes, this project also
            utilized multiple burner fabrication processes that were combined to create this single
            product.
          </p>
          <p>
            The finished burner featured dimensions of 19" in length and 7" in width, and was
            constructed from .035" thick 304 stainless steel tubing, in diameters of 1.25" and
            0.500". The raw material was cut to size on our tube shear prior to being ported on a
            specially developed, proprietary CNC porting machine. Fabrication also utilized our
            automatic plasma cutter, with the tube components being formed on our CNC bender and
            punch press. Other operations included air shutter modifications, the application of a
            T-notch end and venturi smash. Components were joined with MIG welding before final
            inspection and shipping to the customer.
          </p>
          <p>
            For more information on this project, see the table below or&nbsp;
            <span className="fake-link" onClick={() => onNavigate(`#${SiteViews.Contact}`)}>
              contact us
            </span>
            &nbsp;directly.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="primary" onClick={() => onNavigate(`#${SiteViews.Portfolio}`)}>
            View Other Portfolio
          </Button>
        </Col>
        <Col>
          <Button
            variant="primary"
            className="float-right"
            onClick={() => onNavigate(`#${SiteViews.Quote}`)}
          >
            Request Quote
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="project-header">Project Highlights:</h4>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p>
            <strong>Product Description</strong> This burner is used for outdoor grilles and is
            equipped with an entrance leg.
          </p>
          <div style={{ paddingBottom: '1rem' }}>
            <div>
              <strong>Custom Fabrication Capabilities Applied/Processes</strong>
            </div>
            <div className="float-right" style={{ paddingRight: 36 }}>
              Secondary: <br />
              Air Shutter Modification <br />
              End Smash <br />
              Venturi Smash <br />
              T-Notch
            </div>
            <div>
              Primary: <br />
              Fabrication
              <br />
              MIG Welding <br />
              Porting <br />
              Shearing <br />
              Bending <br />
              Plasma Cutting <br />
              Punching
            </div>
          </div>
          <p>
            <strong>Equipment Used to Manufacture Part</strong> <br />
            Tube Shear, Proprietary CNC Porting Machines, Automatic Plasma Cutter, CNC Bender, Punch
            Press, MIG Welder
          </p>
          <p>
            <strong>Overall Part Dimensions</strong> <br />
            Length: 19" Width: 7"
          </p>
        </Col>
        <Col md={6}>
          <p>
            <strong>Tightest Tolerances</strong> <br />± 0.003" on Ports
          </p>
          <p>
            <strong>Material Used</strong> <br />
            304 Stainless Steel Tubing <br />
            O.D.: Ø 1.25" and Ø 0.500" <br />
            Thickness: 0.035"
          </p>
          <p>
            <strong>Material Finish</strong> <br />
            Raw/Machined
          </p>
          <p>
            <strong>Industry for Use</strong> <br />
            Outdoor Grilling Industry
          </p>
          <p>
            <strong>Standards Met</strong> <br />
            Customer Specifications, 2D CAD Drawing
          </p>
          <p>
            <strong>Product Name</strong> <br />W Burner
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default RingBurner;
