import { useRoutes } from 'hookrouter';
import React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import About from '../containers/About';
import Applications from '../containers/Applications';
import Contact from '../containers/Contact';
import Engineering from '../containers/Engineering';
import Fabrication from '../containers/Fabrication';
import Home from '../containers/Home';
import Portfolio from '../containers/Portfolio';
import AluminumConnectureTube from '../containers/Portfolio/aluminumConnectureTube';
import DoubleWalledManifold from '../containers/Portfolio/doubleWalledManifold';
import RingBurner from '../containers/Portfolio/ringBurner';
import SteelBurner from '../containers/Portfolio/steelBurner';
import UShapedBurner from '../containers/Portfolio/uShapedBurner';
import YShapedBurner from '../containers/Portfolio/yShapedBurner';
import PrivacyPolicy from '../containers/PrivacyPolicy';
import RequestQuote from '../containers/Quote';

const routes = {
  '/': () => <Home />,
  '/about': () => <About />,
  '/fabrication': () => <Fabrication />,
  '/engineering': () => <Engineering />,
  '/applications': () => <Applications />,
  '/portfolio': () => <Portfolio />,
  '/contact': () => <Contact />,
  '/quote': () => <RequestQuote />,
  '/metal-forming-stainless-steel-tube': () => <RingBurner />,
  '/custom-fabrication-stainless-steel-burner': () => <SteelBurner />,
  '/precision-fabrication-y-shaped-stainless-steel-burner': () => <YShapedBurner />,
  '/custom-fabricated-double-wall-manifold': () => <DoubleWalledManifold />,
  '/metal-fabrication-commercial-burner': () => <UShapedBurner />,
  '/custom-fabricated-aluminum-fireplace-connector-tube': () => <AluminumConnectureTube />,
  '/privacy-policy': () => <PrivacyPolicy />,
}

const App: React.FC = () => {
  const routeResult = useRoutes(routes);

  return (
    <div id="wm-root">
      <Header />
      {routeResult || <Home />}
      <Footer />
    </div>
  );
}

export default App;
