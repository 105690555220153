import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { SiteViews } from '../../models/enums/siteViews';
import onNavigate from '../../modules/onNavigate';

import accentImage from '../../images/double_wall_img.jpg';

const DoubleWalledManifold: React.FC = () => {
  return (
    <Container className="page-container">
      <Row>
        <Col xs={12}>
          <h3>Fabrication of a Double Wall Manifold for the Commercial Boiler Industry</h3>
          <p>
            <img
              src={accentImage}
              alt="Double Wall Manifold"
              style={{ marginRight: 12 }}
              className="float-left img-fluid"
            />
            Specializing in tube and pipe fabrication for over 50 years, Woodmack Products, Inc's
            expertise in metal fabrication allows for precision manufacturing for a wide array of
            applications.
          </p>
          <p>
            A manufacturer of commercial boilers contracted us to produce a low volume run of this
            4" double wall manifold. Because of the extreme conditions that this product would face,
            it was constructed from schedule 40 pipe and ¼" plate steel. The manifold was
            manufactured using 3" sch. 40 pipe for the inside wall, with 3.5" sch. 40 pipe for the
            outside wall. The center tube was made from made from a 1" sch. 40 pipe, with both
            flanges being formed from ¼" HR plate steel. All components were formed in-house,
            utilizing plasma cutting and cut off equipment, as well as threading. To ensure that the
            manifold would seal properly it was welded using a gas tight, MIG welding technique.
          </p>
          <p>
            At Woodmack we take quality seriously. Everything we manufacture is subject to stringent
            quality control measures. This product received various dimensional inspections as well
            as leak testing prior to shipping.
          </p>
          <p>
            To learn more about this project or how Woodmack can help you with your complex project,
            see the table below or&nbsp;
            <span className="fake-link" onClick={() => onNavigate(`#${SiteViews.Contact}`)}>
              contact us
            </span>
            &nbsp;directly.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="primary" onClick={() => onNavigate(`#${SiteViews.Portfolio}`)}>
            View Other Portfolio
          </Button>
        </Col>
        <Col>
          <Button
            variant="primary"
            className="float-right"
            onClick={() => onNavigate(`#${SiteViews.Quote}`)}
          >
            Request Quote
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="project-header">Project Highlights</h4>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p>
            <strong>Product Description</strong>
            <br />
            Double Wall Manifold
          </p>
          <p>
            <strong>Steel Pipe Fabrication Capabilities Applied/Processes</strong> <br />
            Primary: <br />
            Plasma Cutting <br />
            Gas Tight Welding <br />
            Threading
          </p>
          <p>
            <strong>Equipment Used to Manufacture Part</strong> <br />
            Cut off Machines <br />
            Automatic Plasma Cutter <br />
            Threader <br />
            MIG Welder
          </p>
          <p>
            <strong>Expertise</strong> <br />
            Metal Fabrication
          </p>
          <p>
            <strong>Overall Part Dimensions</strong> <br />
            OD: Ø 4" Length: 9"
          </p>
        </Col>
        <Col md={6}>
          <p>
            <strong>Tightest Tolerances</strong> <br />± 0.016", Bracket Holes
          </p>
          <p>
            <strong>Material Used</strong> <br />
            3-1/2" SCH 40 Pipe 3" SCH 40 Pipe 1" SCH 40 Pipe 1/4" HR steel plate
          </p>
          <p>
            <strong>Industry for Use</strong> <br />
            Commercial Boiler Industry
          </p>
          <p>
            <strong>Volume</strong> <br />
            Low
          </p>
          <p>
            <strong>Standards Met</strong> <br />
            Customer Specifications, 2D CAD Drawing
          </p>
          <p>
            <strong>Product Name</strong> <br />
            4" Double Wall Manifold
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default DoubleWalledManifold;
