import { navigate } from 'hookrouter';

const onNavigate = (siteView: string) => {
    switch (siteView) {
        case '#home':
            navigate('/');
            break;
        default:
            const target = siteView.replace('#', '/');
            navigate(target);
            break;
    }
}

export default onNavigate;
