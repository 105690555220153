import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

const PrivacyPolicy : React.FC = () => {

    return (
        <Container className="page-container">
            <Row>
                <Col xs={12}>
                    <h2>Privacy Policy</h2>
                </Col>
            </Row>
        </Container>
    );

}

export default PrivacyPolicy;
