import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PortfolioList from '../../components/portfolioList';

import accentImage from '../../images/custom_tube_img.jpg';

const Fabrication: React.FC = () => {
  return (
    <Container className="page-container">
      <Row>
        <Col xs={12}>
          <h2>CUSTOM TUBE &amp; PIPE FABRICATIONS</h2>
          <p>
            <img
              src={accentImage}
              alt="Custom Tube"
              className="float-left img-fluid"
              style={{ marginRight: 12 }}
            />
            Machining, bending, forming and welding services are available services to create custom
            tubes and pipes
          </p>
          <p>
            At Woodmack Products Inc. we provide custom tube and pipe fabrication services,
            specializing in the gas appliance industry. We offer components manufactured from
            aluminum, stainless steel and steel tubing, in various forms and sizes such as round and
            rectangular tube, as well as solid bodies, in diameters from .25" to 3".
          </p>
          <p>
            WPI is equipped for multiple manufacturing processes such as CNC machining, mandrel
            bending, and forming. Our machining and forming capabilities are augmented by advanced
            welding capabilities. We employ robotic, semi-robotic, and manual welding which include
            arc, resistance, GMAW and TIG processes. In addition, high pressure and high purity
            welding is also incorporated. All of these advanced processes are performed in-house,
            which allows for complete control over quality and scheduling. WPI's modern facility and
            specialty production shop can also offer production volume from prototype and one-off
            items, all the way to large scale, long runs.
          </p>
          <p>
            As specialists in tube and pipe fabrication for over 50 years, we can provide the
            expertise and industry knowledge unavailable from a generalist. Delivering not only
            superior manufacturing products, but insight and engineering support to take full
            advantage of modern processes and materials.
          </p>
          <p>
            For more information about our tube and pipe fabrication services, see the table below
            or contact us directly.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h4>Examples of Custom Tube &amp; Pipe Projects</h4>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <PortfolioList />
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="project-header">Custom Tube and Pipe Fabrication Capabilities:</h4>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p>
            <strong>Industry Focus</strong>
            <br />
            Gas appliances
          </p>
          <p>
            <strong>Applications</strong> <br />
            Gas burners
          </p>
          <p>
            <strong>Additional Services Provided</strong> <br />
            Brazing <br />
            Crimping <br />
            Cutting <br />
            De-Burring <br />
            Drilling <br />
            End Trimming <br />
            Plasma Cutting <br />
            Leak Checking <br />
            Machining <br />
            Repair <br />
            Welding <br />
            Beading <br />
            Flaring <br />
            Fixtures/tooling <br />
            Machining <br />
            Swaging <br />
            Polishing <br />
            Porting <br />
            Design assistance
          </p>
          <p>
            <strong>Materials</strong> <br />
            Aluminum <br />
            Stainless Steel <br />
            Steel
          </p>
          <p>
            <strong>Fabrication Method</strong> <br />
            CNC <br />
            Forming <br />
            Mandrel Bending
          </p>
          <p>
            <strong>Welding Process</strong> <br />
            MIG <br />
            TIG <br />
            FCAW (Flux Core Arc Welding <br />
            GMAW (Gas Metal Arc Welding <br />
            SMAW ( Shielded Metal Arc Welding <br />
            Projection - single or multi-station <br />
            Spot
          </p>
        </Col>
        <Col md={6}>
          <p>
            <strong>Welding Operation</strong> <br />
            Robotic <br />
            Automatic <br />
            Semi-Automatic
          </p>
          <p>
            <strong>Finish</strong> <br />
            Painting
          </p>
          <p>
            <strong>Body Cross Section</strong> <br />
            Round <br />
            Rectangular <br />
            Also, solid bodies and plates
          </p>
          <p>
            <strong>Production Volume</strong> <br />
            Specialty Production Shop <br />
            Prototype <br />
            Low Volume <br />
            Small Run <br />
            High Volume <br />
            Large Run <br />
            Long Run <br />
            Large Scale Production Volume Run
          </p>
          <p>
            <strong>Fabrication Features</strong> <br />
            Burr-less Chamfer <br />
            Chipless <br />
            Flanging <br />
            High Pressure <br />
            High Purity <br />
            Sanitary <br />
            Serpentine
          </p>
          <p>
            <strong>Industry Standards</strong> <br />
            AGA standards
          </p>
          <p>
            <strong>File Formats</strong> <br />
            AutoCAD (DWG,DWZ) <br />
            DXF <br />
            Inventor (IDW, IPT)
          </p>
          <p>
            <strong>Outside Diameter</strong> <br />
            Min: ¼ in Max: 3 in
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Fabrication;
