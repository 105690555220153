export default function quoteFormValidation(values: any) {
  const errors: any = {};

  if (!values.name) {
    errors.name = 'Name is Required!';
  }

  if (!values.email) {
    errors.email = 'Email is Required!';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  if (!values.phone) {
    errors.phone = 'Phone Number is Required!';
  }

  return errors;
}
