import { useEffect, useState } from 'react';

const useForm = (callback: () => void, validate: (values: any) => any) => {
  const [values, setValues] = useState({} as any);
  const [errors, setErrors] = useState({} as any);
  const [pendingSubmission, setPendingSubmission] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && pendingSubmission) {
      callback();
      setIsSending(true);
    }
  }, [errors, callback, pendingSubmission]);

  const handleSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }

    setErrors(validate(values));
    setPendingSubmission(true);
  };

  const handleChange = (event: any) => {
    event.persist();
    // tslint:disable-next-line: no-shadowed-variable
    setValues((values: any) => {
      const target = event.target as any;
      return { ...values, [target.name]: target.value };
    });
  };

  const handleCheckboxChange = (event: any) => {
    event.persist();
    // tslint:disable-next-line: no-shadowed-variable
    setValues((values: any) => {
      const target = event.target as any;
      return { ...values, [target.name]: target.checked };
    });
  };

  return {
    handleChange,
    handleCheckboxChange,
    handleSubmit,
    values,
    errors,
    setValues,
    pendingSubmission,
    setIsSubmitting: setPendingSubmission,
    isSending,
    isSubmitted,
    setIsSubmitted,
    hasError,
    setHasError,
    setIsSending,
  };
};

export default useForm;
